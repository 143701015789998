import React, { Component } from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import iosPush from '../images/ios-push.svg'
import lineUpTitle from '../images/line-up-title.svg'
import topText from '../images/top-text.svg'
import appleIcon from '../images/apple-icon.svg'
import IosPushButton from './ios-push-button'
import ClickForAR from './click-for-ar'
const isBrowser = typeof window !== `undefined`

class ContestView extends Component {
  constructor(props) {
    super(props)
    let socialIOS = false
    let arLink = this.props.data.markdownRemark.frontmatter.ar_link
    this.state = {
      pageQuery: this.props.pageQuery,
      socialIOS: socialIOS,
      arLink: arLink,
      mobileOs: this.getMobileOS,
      iosPushButton: null,
      clickForAR: <ClickForAR />,
      arRel: ''
    }
    this.inputRef = React.createRef()
  }

  componentDidMount(){
    const getMobileOS = () => {
      if(isBrowser){
        const ua = navigator.userAgent;
        return ua
      }else{
        return ""
      }
    }

    const clickLink = () => {
      this.inputRef.current.click()
    }

    let os = getMobileOS()
    let iosPushButton = null
    let clickForAR = <ClickForAR />
    let redirected = false
    let arRel = ''
    let arLink = this.props.data.markdownRemark.frontmatter.ar_link
    let utm = this.props.pageQuery
    if(/iPad|iPhone|iPod|iPad Simulator|iPhone Simulator|iPod Simulator/gmi.test(os)){
      arLink = `https://virtual-showroom.forms-surfaces.net/AR/${arLink.replace('/a/','')}.usdz`
      if(/Instagram|FBIOS|Twitter|LinkedInApp/gmi.test(os)){
        let utmString = ''
        if(utm){
          if(utm.utm_source){
            if(!utm.utm_source.includes('-redirect-redirect')){
              utm.utm_source += '-redirect'
            }
          }
          utm.redirected = true
          let searchParams = new URLSearchParams(utm)
          utmString += '?' + searchParams.toString()
        }
        arLink = this.props.location.pathname + utmString
        iosPushButton = <IosPushButton />
        clickForAR = null
      }else{
        if(utm){
          if(utm.redirected){
            redirected = true
          }
        }
        arRel = `ar`
      }
    }else if(/android/i.test(os)){
      arLink = `intent://arvr.google.com/scene-viewer/1.0?file=https://virtual-showroom.forms-surfaces.net/AR/${
        arLink.replace('/a/','')
      }.glb&mode=ar_only#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://virtual-showroom.forms-surfaces.net;end;`
    }else{
      if(utm){
        if(utm.redirected){
          redirected = true
        }
      }
    }
    this.setState(
      {
        arLink: arLink,
        iosPushButton: iosPushButton,
        clickForAR: clickForAR,
        mobileOs: os,
        arRel: arRel
      }, function(){
        if(redirected){
          clickLink()
        }
      }
    )
  }

  render() {
    let arLink = this.props.data.markdownRemark.frontmatter.ar_link
    let iosPushButton = null
    let lineUpTitleImage = <div style={{
      backgroundImage: `url(${topText})`,
      position: 'absolute',
      left: '8vh',
      top: '1vh',
      width: 'calc(100% - 16vh)',
      height: '50vh',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center',
      zIndex: 1
    }} />
    const image = getImage(this.props.data.markdownRemark.frontmatter.product_image_file)

    return (
      <a style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }} href={this.state.arLink} rel={this.state.arRel} ref={this.inputRef}>
        <img src={appleIcon} style={{width: '0px',height: '0px'}}/>
        <div style={{
          width: '56.842vh',
          height: 'calc(100% - 10vh)',
          position: 'relative'
        }}>
          {lineUpTitleImage}
          <div style={{
            backgroundImage: `url(${lineUpTitle})`,
            position: 'absolute',
            left: '3.5vh',
            bottom: '2vh',
            width: 'calc(50% - 2vh)',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom left',
            zIndex: 1
          }} />
          <GatsbyImage image={image} alt={this.props.data.markdownRemark.frontmatter.product_title} style={{
            height: 'calc(100% - 10vh)',
            margin: '10vh 5vh 0 5vh',
            width: 'auto'
          }}
          imgStyle={{ objectFit: 'contain' }}/>
          {this.state.clickForAR}
          {this.state.iosPushButton}
        </div>
      </a>
    )
  }
}

export default ContestView
