import React, { Component } from 'react'
import iosPush from '../images/ios-push.svg'
import appleIcon from '../images/apple-icon.svg'

class IosPushButton extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  render() {
    return(
      <div style={{
        position: 'absolute',
        right: '3vh',
        bottom: '3vh',
        width: 'calc(25% - 2vh)',
        height: '100%',
        zIndex: 1
      }}>
        <div className='linia-pop-up-preview' />
        <div className='linia-press-message'/>
        <div className='linia-hold-message' />
        <div className="touch-icon-apple">
          <div className="text-top"></div>
          <div className="waves"></div>
          <div className="hand"></div>
        </div>
      </div>
    )
  }
}

export default IosPushButton
