import React, { Component } from 'react'
import { Router } from '@reach/router'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import ContestView from '../components/contest-view'
import SEO from '../components/seo'
import { useLocation } from '@reach/router'
import queryString from 'query-string'

const App = ({data, pageContext, props}) => {
  const location = useLocation()
  const pageQuery = queryString.parse(location.search)
  return(
    <Layout>
    <SEO title={data.markdownRemark.frontmatter.product_title}/>
    <ContestView data={data} pageQuery={pageQuery} location={location} />
    </Layout>
  )
}

export default App

export const query = graphql`
query($id: String!) {
  markdownRemark(id: {eq: $id}) {
    frontmatter {
      product_title
      product_image
      title
      ar_link
      product_image_file {
        publicURL
        childImageSharp {
          gatsbyImageData(
            width: 600,
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
    id
  }
}
`
