import React, { Component } from 'react'

class ClickForAR extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  render() {
    return(
      <div className="touch-icon">
        <div className="text-top"></div>
        <div className="waves"></div>
        <div className="hand"></div>
      </div>
    )
  }
}

export default ClickForAR
